function RoadmapStep({ number, content }) {
  const isEven = parseInt(number) % 2 === 0;

  return (
    <>
      <div
        className={`flex gap-[20px] lg:gap-[40px] lg:items-center self-start lg:mb-[100px] ${isEven ? "lg:flex-row-reverse" : ""} ${
          number === "5" && "lg:!mb-0"
        }`}
      >
        <div className="self-end w-[507px] text-lg font-bold leading-7 text-white hidden lg:flex ">
          <div className="flex flex-col justify-center">
            <div className="flex shrink-0 bg-lime-500 rounded-tl-xl rounded-bl-xl h-full w-[9px]" />
          </div>
          <div className="grow shrink-0 px-[54px] rounded-none basis-0 rounded-tr-xl rounded-br-xl bg-white/5 w-fit py-[52px] lg:text-[28px]">{content}</div>
        </div>
        <div className="flex flex-col justify-center items-center px-1 size-5 lg:size-9 bg-gray-800 rounded-full border-2 border-[#6FDA44] border-solid z-10">
          <div className="flex shrink-0 size-2.5 lg:size-6 bg-[#6FDA44] rounded-full" />
        </div>
        <div className="text-base font-bold leading-5 text-white lg:text-[28px]">STEP {number}</div>
      </div>
      <div className={`flex lg:hidden mb-[64px] self-end mt-[26px] ml-[38px] w-full text-lg font-bold leading-7 text-white max-w-[308px] ${number === "5" && "mb-0"}`}>
        <div className="flex flex-col justify-center">
          <div className="flex shrink-0 bg-lime-500 rounded-tl-xl rounded-bl-xl h-full w-[9px]" />
        </div>
        <div className="grow shrink-0 px-9 py-10 rounded-none basis-0 rounded-tr-xl rounded-br-xl bg-white/5 w-fit text-[18px]">{content}</div>
      </div>
    </>
  );
}

export default RoadmapStep;
