import React from "react";
import FeatureCard from "./FeatureCard";

function TokenFeatures() {
  return (
    <section id="what-makes-our-token-different" className="flex flex-col px-6 pt-16 pb-[100px] w-full text-center bg-zinc-100 text-zinc-900 lg:pt-[94px]">
      <h2 className="self-center text-[#192022] text-[32px] lg:text-[40px] leading-[125%] font-bold mb-[32px] lg:mb-[64px]">What Makes Our Token Different?</h2>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-[25px] max-w-[1157px] mx-auto">
        <FeatureCard
          image="/assets/images/support-mobile.png"
          title="Support"
          description="Purchasing our token means that your support will allow us to work with artists to create unique content. You will receive a return based on the sale of artist NFTs."
        />

        <FeatureCard
          image="/assets/images/value-mobile.png"
          title="Value"
          description="Unlike some tokens that rely on the next person purchasing to have value, our token will increase in value based on more artists being on our platform and more NFT sales."
        />

        <FeatureCard
          image="/assets/images/long-term-mobile.png"
          title="Long Term Goals"
          description="Our ecosystem is designed to support long term growth; we are not a pump and dump token designed to disappear as fast as it appears."
          imageClass="aspect-[1.4]"
          paddingClass="py-12"
          isLast
        />
      </div>
    </section>
  );
}

export default TokenFeatures;
