function ContactForm() {
  return (
    <section id="contact-us" className="flex flex-col px-6 pt-16 lg:pt-[100px] pb-[100px] lg:pb-[120px] w-full bg-gray-800">
      <h2 className="self-center text-[32px] lg:mb-[64px] font-bold leading-[125%] lg:text-[40px] text-center text-white">Contact Us</h2>

      <div className="w-full flex flex-col lg:w-[618px] mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[12px] lg:gap-[32px]  w-full">
          <input
            type="text"
            placeholder="Your name"
            className="p-3.5 h-[48px] mt-[32px] lg:mt-0 text-sm text-white rounded-lg !border !border-solid bg-white/10 !border-white/20"
          />
          <input
            type="email"
            placeholder="Email Address"
            className="p-3.5 h-[48px] lg:mt-0 text-sm text-white rounded-lg !border !border-solid bg-white/10 !border-white/20"
          />
        </div>

        <input
          type="text"
          placeholder="Subject"
          className="p-3.5 mt-[12px] lg:mt-[32px] h-[48px] text-sm text-white whitespace-nowrap rounded-lg !border !border-solid bg-white/10 !border-white/20"
        />

        <textarea
          placeholder="Your message..."
          className="px-3.5 pt-3.5 pb-24 mt-[12px] lg:mt-[32px] text-sm text-white rounded-lg !border !border-solid bg-white/10 !border-white/20"
        ></textarea>

        <button className="overflow-hidden self-center leading-none px-[32px] py-[16px] mt-8 text-sm font-bold text-gray-800 bg-lime-500 rounded">
          Send Message
        </button>
      </div>
    </section>
  );
}

export default ContactForm;
