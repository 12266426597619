import React from "react";
import RoadmapStep from "./RoadmapStep";

function Roadmap() {
  return (
    <section id="our-roadmap" className="flex flex-col px-6 pt-[64px] lg:pt-[100px] pb-[100px] lg:pb-[120px] w-full bg-[#2A3538] relative">
      <div className="relative w-full lg:w-[1128px] mx-auto">
        <div className="absolute top-[55px] lg:left-1/2 lg:!bottom-[84px] lg:top-[149px] bottom-0 left-[7.5px] w-1 bg-white/20 rounded-[4px]" />
        <h2 className="self-center text-[32px] lg:text-[40px] font-bold leading-none mb-[43px] lg:mb-[64px] text-center text-white">Our Roadmap</h2>
        <RoadmapStep
          number="1"
          content={
            <>
              ✏️ Planning <span className="font-normal">and</span> conceptualisation <span className="font-normal">of</span>{" "}
              <span className="text-lime-500">Sniser</span> <span className="font-normal">as</span> a business
            </>
          }
          height="h-[161px]"
        />

        <RoadmapStep
          number="2"
          content={
            <>
              🤝<span className="text-lime-500"> Community</span> building
            </>
          }
          height="h-[107px]"
        />

        <RoadmapStep
          number="3"
          content={
            <>
              🚀<span className="text-lime-500"> Token</span> launch
            </>
          }
        />

        <RoadmapStep
          number="4"
          content={
            <>
              ⚙️ <span className="text-lime-500">Development</span> <span className="font-normal">of</span> <span className="text-lime-500">content</span>{" "}
              <span className="font-normal">by acquiring</span> existing copywritten material <span className="font-normal">and</span> working{" "}
              <span className="font-normal">with</span> new artists <span className="font-normal">in the development of </span>their content.
            </>
          }
        />

        <RoadmapStep
          number="5"
          content={
            <>
              <span className="text-lime-500">🎯 Form strategic partnerships</span>
              <span className="font-normal"> with</span> mainstream media services
              <span className="font-normal"> to</span> integrate
              <span className="font-normal"> their</span>
              <span className="font-normal"> offerings</span>
              <span className="font-normal"> into</span>
              <span className="font-normal"> the</span> blockchain space.
            </>
          }
        />
      </div>
    </section>
  );
}

export default Roadmap;
