import React from "react";

function StatisticItem({ value, description }) {
  return (
    <div className="flex flex-col">
      <div className="mt-10 lg:mt-0 text-center text-[#6FDA44] text-[32px] leading-[48px] lg:leading-[78px] lg:text-[52px]">{value}</div>
      <div className="mt-1 text-sm font-semibold text-center lg:text-[20px]">{description}</div>
    </div>
  );
}

export default StatisticItem;
