"use client";
import { useState } from "react";

function FAQItem({ question, answer, questionClassName = "", gapClassName = "gap-5" }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <article className={`p-[24px] lg:p-[32px] w-full leading-6 rounded-xl border border-solid border-zinc-900 ${answer && !isOpen ? "bg-white" : ""}`}>
      <div className={`flex ${gapClassName} justify-between font-bold`}>
        <div className={`${questionClassName} lg:text-[28px]`}>{question}</div>
        <img
          src={isOpen ? "/assets/images/x.png" : "/assets/images/plus.png"}
          className={`object-contain shrink-0 ${isOpen ? "self-start" : "my-auto"} size-5 cursor-pointer lg:size-8 aspect-square`}
          alt={isOpen ? "Collapse" : "Expand"}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      {isOpen && answer && <div className="mt-3 lg:text-[20px] lg:mt-[22px] lg:leading-[150%]">{answer}</div>}
    </article>
  );
}

export default FAQItem;
