function WhitepaperTag({ text, className = "" }) {
  return (
    <div
      className={`overflow-hidden lg:h-[52px] self-stretch px-[24px] lg:px-[32px] py-[12px] lg:py-[16px] leading-[17px] rounded-xl border-2 border-solid text-[14px] flex-shrink-0 border-[#192022] ${className}`}
    >
      {text}
    </div>
  );
}

export default WhitepaperTag;
