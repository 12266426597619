import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import "./NavigationBar.css";

function NavigationBar() {
  const items = [
    {
      id: "our-story",
      title: "Our Story",
    },
    { id: "what-makes-our-token-different", title: "What makes our token different" },
    { id: "our-roadmap", title: "Our Roadmap" },
    { id: "our-whitepaper", title: "Our whitepaper" },
    { id: "faqs", title: "FAQs" },
    { id: "contact-us", title: "Contact Us" },
  ];
  const [startIndex, setStartIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const isMobile = useMediaQuery("(max-width:420px)");

  const handleClick = () => {
    setIsSliding(true);
    setTimeout(() => {
      setStartIndex((prevIndex) => (prevIndex + 1) % (items.length - 2));
      setIsSliding(false);
    }, 100);
  };

  const handleItemClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav className="flex w-full gap-[20px] px-6 py-10 text-base text-white bg-[#2A3538]">
      <div className={`overflow-hidden flex-1 justify-center flex gap-[16px] lg:gap-[64px] ${isSliding ? "sliding" : ""}`}>
        {items.slice(startIndex, startIndex + (isMobile ? 3 : 7)).map((item, index) => (
          <div key={index} className="flex-shrink-0 item cursor-pointer" onClick={() => handleItemClick(item.id)}>
            {item.title}
          </div>
        ))}
      </div>
      <img
        src="/assets/images/chevron-right.png"
        className={`object-contain w-6 aspect-square cursor-pointer flex-shrink-0 ${isMobile ? "block" : "hidden"}`}
        alt="Navigation Icon"
        onClick={handleClick}
      />
    </nav>
  );
}

export default NavigationBar;
