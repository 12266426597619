"use client";
import FAQItem from "./FAQItem";

function FAQ() {
  return (
    <section id="faqs" className="flex flex-col px-6 pt-16 lg:pt-[100px] pb-[100px] lg:pb-[120px] w-full text-base bg-zinc-100 text-zinc-900">
      <h2 className="self-center text-[32px] mb-[32px] lg:mb-[64px] font-bold leading-10 text-center lg:text-[40px]">Our Frequently Asked Questions</h2>

      <div className="flex flex-col w-full lg:w-[1156px] mx-auto gap-[12px] lg:gap-[32px]">
        <FAQItem
          question="What is a NFT?"
          answer="An NFT (Non-Fungible Token) is a type of digital asset that represents ownership or proof of authenticity of a unique item or piece of content using blockchain technology. Unlike cryptocurrencies like Bitcoin or Ethereum, which are fungible (meaning each unit is identical and interchangeable), NFTs are one-of-a-kind or have limited editions making them unique."
        />

        <FAQItem
          question="What is a crypto wallet?"
          answer="A crypto wallet is a digital tool that allows you to store, send, and receive cryptocurrencies like Bitcoin, Ethereum, and other digital assets, including NFTs."
        />

        <FAQItem
          question="What is Sniser?"
          answer="Sniser is an NFT marketplace. The NFTs on our website allow you to view exclusive video and audio content with your crypto wallet."
        />

        <FAQItem
          question="What is your token?"
          answer="Purchasing our token will allow us to buy existing or commission content that will be available exclusively on our website. When people purchase the content that you have partially funded, we will send you a percentage of the profits from the sale."
        />

        <FAQItem
          question="How much will I earn if I invest in the Sniser token?"
          answer="This will depend on various factors such as the amount of content that is put on our platform and the number of NFTS sold."
          initiallyOpen={false}
          questionClassName="w-[246px] lg:w-full"
        />

        <FAQItem
          question="Can I determine which artists and content creators you will work?"
          answer="At the moment no. Our focus is on selecting content creators that have an existing portfolio and following on various social media formats."
          questionClassName="grow shrink w-[242px] lg:w-full"
          gapClassName="gap-8"
        />

        <FAQItem
          question="I am an artist and would like to work with you. What should I do?"
          answer={
            <>
              Our priority will be on purchasing content from existing artists with a catalogue and fan base. This will enable us to quickly monetise content on
              our platform and provide returns to those with our dividend coin. If you fit into these criteria upload your content to us via this page:{" "}
              <a href="https://sniser.io/upload" className="font-semibold underline text-lime-500" target="_blank" rel="noopener noreferrer">
                https://sniser.io/upload
              </a>
            </>
          }
          questionClassName="grow shrink w-[242px] lg:w-full"
          gapClassName="gap-8"
        />

        <FAQItem
          question="How do I know that you are genuine and not another crypto scam?"
          answer="All investments should be treated as speculatively and this is even more true within the crypto space due to the ease of creating a token. Take all such investments including us with a “pinch of salt” and only invest an amount that you are prepared to lose. Having said that most of the most popular crypto assets were initially high risk and that is why they are also high rewarding."
          questionClassName="grow shrink w-[242px] lg:w-full"
          gapClassName="gap-8"
        />

        <FAQItem
          question="I have more questions that are not answered here. How can I contact you?"
          answer="Send us an email: support@sniser.com"
          questionClassName="grow shrink w-[242px] lg:w-full"
          gapClassName="gap-8"
        />
      </div>
    </section>
  );
}

export default FAQ;
