function Hero() {
  return (
    <section className="flex flex-col items-center justify-center py-[150px] xl:py-[210px] w-full bg-[#6FDA44]">
      <h1 className="text-[32px] xl:text-[52px] font-bold leading-[125%] text-center text-[#192022]">
        Earn dividends by supporting the next generation of artists
      </h1>
      <p className="mt-8 text-base xl:text-2xl leading-6 text-center text-[#192022]">
        By purchasing our token, you will enable artists to create unique content and share in their success.
      </p>
      <div className="w-full flex justify-center">
        <button className="overflow-hidden self-stretch px-8 py-[16px] mt-8 text-sm font-bold text-white bg-[#2A3538] rounded leading-4 w-[208px]">
          Buy our token now!
        </button>
      </div>
    </section>
  );
}

export default Hero;
