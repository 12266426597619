import React from "react";
import StatisticItem from "./StatisticItem";

function OurStory() {
  return (
    <section
      id="our-story"
      className="flex flex-col items-center px-6 xl:px-[142px] mt-8 lg:mt-[100px] w-full font-bold text-[#2A3538] pb-[64px] lg:pb-[120px]"
    >
      <div className="flex flex-col lg:flex-row items-center lg:gap-[52px] mb-6 lg:mb-[80px]">
        <img
          src="/assets/images/flow-bro-mobile.png"
          className="object-contain self-stretch w-full aspect-square block lg:hidden"
          alt="Our Story Illustration"
        />
        <img
          src="/assets/images/flow-bro.png"
          className="object-contain self-stretch w-full aspect-square hidden lg:block max-w-[457px]"
          alt="Our Story Illustration"
        />
        <div className="flex flex-col">
          <h2 className="self-start text-[32px] lg:text-[40px] mt-8 lg:mt-0 lg:text-gray-800 leading-none text-[#192022]">Our story</h2>
          <p className="self-stretch mt-3 lg:mt-[32px] lg:mb-0 text-sm leading-5 font-normal xl:max-w-[644px] lg:text-[20px] lg:leading-[150%] tracking-normal">
            The Sniser ecosystem is born out of support for up-and-coming artists that can’t afford to rely on streaming to provide an income. We therefore work
            with artists to generate unique content that they can hide behind an NFT wall. The only way that people can view such content is by purchasing the
            NFT ensuring that the artist gets paid.
            <br />
            <br />
            As an investor in our token, you will be investing in artists on this platform and will share in the proceeds from their NFT sales.
          </p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row lg:gap-[54px]">
        <StatisticItem value="$42.41 bn" description="Value of audio streaming industry" />
        <StatisticItem value="$135 bn" description="Value of video streaming industry" />
        <StatisticItem value="0" description="Companies like Sniser (now)" />
      </div>
    </section>
  );
}

export default OurStory;
