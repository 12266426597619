import React from "react";

function SocialLinks() {
  return (
    <section className="flex gap-5 justify-between px-20 py-11 w-full bg-lime-500">
      <div className="flex items-center gap-[40px] lg:gap-[80px] w-fit">
        {Array.from({ length: 10 }).map((_, index) => (
          <React.Fragment key={index}>
            <img src="/assets/images/logo-social-mobile.png" className="object-contain shrink-0 aspect-[1.03] w-[38px]" alt="Social Media Icon 1" />
            <img src="/assets/images/sniser-mobile.png" alt="" />
          </React.Fragment>
        ))}
      </div>
    </section>
  );
}

export default SocialLinks;
