import { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { useAccount } from "wagmi";
import { polygon, polygonAmoy } from "wagmi/chains";
import { AppContext } from "./Context";
import { CheckOutStatus } from "./components";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NFTDetail from "./components/NFTDetail/NFTDetail";
import ShopNFTDetail from "./components/NFTDetail/ShopNFTDetail";
import NetworkChange from "./network ";
import AboutUs from "./pages/AboutUs";
import AccountDetection from "./pages/AccountDeletion";
import AdminDashboard from "./pages/AdminDashboard";
import ArtistService from "./pages/ArtistService";
import BusinessService from "./pages/BusinessService";
import Checkout from "./pages/Checkout";
import Coin from "./pages/Coin";
import CookiePolicy from "./pages/CookiePolicy";
import Faq from "./pages/Faq";
import Finance from "./pages/Finance";
import ForgotPassword from "./pages/ForgotPassword";
import Guide from "./pages/Guide";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NftAdvertise from "./pages/NftAdvertise";
import Policy from "./pages/PrivacyPolicy";
import PrivateFile from "./pages/PrivateFile";
import PurchaseAnNft from "./pages/PurchaseAnNft";
import ResetPassword from "./pages/ResetPassword";
import ShopNft from "./pages/ShopNft";
import SignUp from "./pages/SignUp";
import TermsAndConditions from "./pages/TermsAndConditions";
import Upload from "./pages/Upload";
import UploadTermConditions from "./pages/UploadTermConditions";
import VideoLibrary from "./pages/VideoLibrary";
import ViewContent from "./pages/ViewContent";
import WhatIs from "./pages/WhatIs";
import WhatIsSniser from "./pages/WhatIsSniser";
import Whitepaper from "./pages/Whitepaper";
import WhyPurchase from "./pages/WhyPurchase";
import Advertise from "./pages/Advertise";
import NotFound from "./pages/NotFound";
import { DEV_MODE } from "./utils/constant";
import { getStorage, setStorage } from "./utils/helper";
import { WalletConnectAlertModal } from "./components/Modal/index";
import CoinPage from "./pages/CoinPage";

function App() {
  const [networkModal, setNetworkModal] = useState(false);
  const [openWalletConnect, setWalletConnect] = useState(false);
  const account = useAccount();
  const { isMobile, userData } = useContext(AppContext);
  console.log(userData);

  const onFirstConsent = ({ cookie }) => {
    setStorage("cookie", "1");
    setWalletConnect(true);
  };

  const onConfirm = () => {
    setStorage("walletAlert", "1");
    setWalletConnect(false);
  };

  useEffect(() => {
    const cookie = getStorage("cookie");

    if (!cookie) {
      CookieConsent.run({
        guiOptions: {
          consentModal: {
            layout: isMobile ? "box" : "box wide",
          },
        },
        categories: {
          necessary: {
            enabled: true, // this category is enabled by default
            readOnly: true, // this category cannot be disabled
          },
          analytics: {},
        },
        language: {
          default: "en",
          translations: {
            en: {
              consentModal: {
                title: "This website use cookies",
                description: `We use cookies to enhance your browsing experience, serve personalized ads or content, and 
                      analyze our traffic. By clicking "Accept All" your consent to our use of cookies.	<a href="https://google.com" target="_blank">Cookie Policy</a>`,
                acceptAllBtn: "Accept all",
                acceptNecessaryBtn: isMobile ? "" : "Reject all",
                showPreferencesBtn: "Learn More",
                closeIconLabel: "X",
              },
              preferencesModal: {
                title: "Manage cookie preferences",
                acceptAllBtn: "Accept all",
                acceptNecessaryBtn: "Reject all",
                savePreferencesBtn: "Accept current selection",
                closeIconLabel: "Close modal",
                sections: [
                  {
                    title: "Somebody said ... cookies?",
                    description: "I want one!",
                  },
                  {
                    title: "Strictly Necessary cookies",
                    description: "These cookies are essential for the proper functioning of the website and cannot be disabled.",

                    //this field will generate a toggle linked to the 'necessary' category
                    linkedCategory: "necessary",
                  },
                  {
                    title: "Performance and Analytics",
                    description:
                      "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                    linkedCategory: "analytics",
                  },
                  {
                    title: "More information",
                    description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>',
                  },
                ],
              },
            },
          },
        },
        onFirstConsent: onFirstConsent,
      });
    }

    if (account.address && account.chainId !== (!DEV_MODE ? polygon : polygonAmoy)?.id) {
      setNetworkModal(true);
    }
  }, [account, isMobile]);

  return (
    <>
      {/* <MoonPayProvider
            apiKey="pk_test_dgQ8t5UfCkvaO6Xbvm5ZDiaViAt82QO"
            debug
        > */}
      <NetworkChange open={networkModal} setOpen={setNetworkModal} />
      <WalletConnectAlertModal open={openWalletConnect} onCheck={onConfirm}></WalletConnectAlertModal>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ResetPassword />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/whitepaper" element={<Whitepaper />} />
        <Route path="/artist-service" element={<ArtistService />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy-policy" element={<Policy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/account-deletion" element={<AccountDetection />} />
        <Route path="/business-service" element={<BusinessService />} />
        <Route path="/myaccount" element={<AdminDashboard />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/checkout/status" element={<CheckOutStatus />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/whatis" element={<WhatIs />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/why-purchase" element={<WhyPurchase />} />
        <Route path="/nft-advertise" element={<NftAdvertise />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/view-content/:id" element={<ViewContent />} />
        <Route path="/purchase-an-nft" element={<PurchaseAnNft />} />
        <Route path="/what-is-sniser" element={<WhatIsSniser />} />
        <Route path="/coin" element={<Coin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/shop" element={<ShopNft />} />
        <Route path="/shop/nft/:id" element={<ShopNFTDetail />} />
        <Route path="/nft/:id" element={<NFTDetail />} />
        <Route path="/private-file/:id" element={<PrivateFile />} />
        <Route path="/video-library/:id" element={<VideoLibrary />} />
        <Route path="/audio-library/:id" element={<VideoLibrary />} />
        <Route path="/upload-term-conditions" element={<UploadTermConditions />} />
        <Route path="/advertise" element={<Advertise />} />
        <Route path="/coin-page" element={<CoinPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="light"
      />
      {/* </MoonPayProvider> */}
    </>
  );
}

export default App;
