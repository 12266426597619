function FeatureCard({ image, title, description, imageClass = "aspect-square", paddingClass = "py-10", isLast = false }) {
  return (
    <article className={`flex flex-col px-10 ${paddingClass} mt-[24px] lg:mt-0 bg-white rounded-xl`}>
      <img src={image} className={`object-contain mx-2.5 w-full ${imageClass} ${isLast && "lg:mt-[44px] lg:mb-[28px]"} `} alt={title} />
      <h3 className="self-center mt-6 text-[28px] text-[#192022] font-bold">{title}</h3>
      <p className="mt-[12px] text-base lg:text-[20px] leading-6 text-[#192022]">{description}</p>
    </article>
  );
}

export default FeatureCard;
