import ContactForm from "../components/CoinPage/ContactForm";
import FAQ from "../components/CoinPage/FAQ";
import Hero from "../components/CoinPage/Hero";
import NavigationBar from "../components/CoinPage/NavigationBar";
import OurStory from "../components/CoinPage/OurStory";
import Roadmap from "../components/CoinPage/Roadmap";
import SocialLinks from "../components/CoinPage/SocialLinks";
import TokenFeatures from "../components/CoinPage/TokenFeatures";
import Whitepaper from "../components/CoinPage/Whitepaper";
import useDocumentTitle from "../useDocumentTitle";

function CoinPage() {
  useDocumentTitle("Sniser provides NFT protected content to ensure artist payment.");

  return (
    <div className="overflow-x-hidden">
      <Hero />
      <NavigationBar />
      <OurStory />
      <TokenFeatures />
      <Roadmap />
      <Whitepaper />
      <FAQ />
      <SocialLinks />
      <ContactForm />
    </div>
  );
}

export default CoinPage;
