import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Loading from "../connectivityAssets/Loading";
import useDocumentTitle from "../useDocumentTitle";
import Payout from "../components/Payout";
import UploadSuccess from "../components/UploadSuccess";
import PersonalInformation from "../components/PersonalInformation";
import FileUpload from "../components/FileUpload";
import axios from "axios";
import { AppContext } from "../Context";

export default function Upload() {
  const matches = useMediaQuery("(max-width:550px)");
  useDocumentTitle("View exclusive NFT protected content | Find files from upcoming artists");

  const [image, setImage] = useState("");
  const [libraryFile, setLibraryFile] = useState([]);
  const [previewFile, setPreviewFile] = useState("");
  const { setAlertState } = useContext(AppContext)
  
  const [loading, setLoading] = useState(false);
  const methods = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      quantity: "",
      desc: "",
      fileType: "Audio",
      payment: "",
    },
  });
  const { handleSubmit, getValues } = methods;
  const [activeStep, setActiveStep] = useState(0);

  const onSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("secure_token", "BLK-lGin834iN");
    formData.append("first_name", getValues("firstName"));
    formData.append("last_name", getValues("lastName"));
    formData.append("email_address", getValues("email"));
    formData.append("image", image);
    formData.append("quantity", getValues("quantity"));
    formData.append("file_type", getValues("fileType"));
    formData.append("description", getValues("desc"));
    formData.append("payment_detail", getValues("payment"));
    formData.append("captcha", getValues("payment"));

    if (libraryFile.length) {
      libraryFile.forEach((item) => {
        formData.append("library_files", item);
      });
    }
    formData.append("preview_file", previewFile);
    axios({
      method: "post",
      // url: "http://localhost:8001/api/upload", // dev mode
      url: "https://api.sniser.io/api/upload",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (res) {
        setLoading(false);
        if (res?.status === 200) {
          setActiveStep((currentStep) => currentStep + 1);
        } else {
          setAlertState({
            open: true,
            message: res?.message,
            severity: "error",
          });
        }
      })
      .catch(() => setLoading(false));
  };

  const formContent = (step) => {
    switch (step) {
      case 0:
        return <PersonalInformation setActiveStep={() => setActiveStep((currentStep) => currentStep + 1)} />;
      case 1:
        return (
          <FileUpload
            setActiveStep={() => setActiveStep((currentStep) => currentStep + 1)}
            setPrevStep={() => setActiveStep((currentStep) => currentStep - 1)}
            setDataImage={setImage}
            setDataLibrary={setLibraryFile}
            setDataPreview={setPreviewFile}
          />
        );
      case 2:
        return <Payout setPrevStep={() => setActiveStep((currentStep) => currentStep - 1)} handleSubmit={onSubmit} />;
      case 3:
        return <UploadSuccess />;

      default:
        break;
    }
  };

  return (
    <>      
      <Loading isLoading={loading} />
      <Box sx={{ backgroundColor: "#6FDA44", pt: "40px" }}>
        <Container sx={{ maxWidth: "682px !important" }}>
          {activeStep !== 3 && (
            <>
              <Typography
                sx={{
                  color: "#2A3538",
                  fontWeight: 800,
                  fontSize: "32px",
                  textAlign: "center",
                  mb: 3,
                }}
              >
                Upload Content
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#2A3538",
                  borderRadius: "12px",
                  padding: "15px 56px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  mb: "24px",
                }}
              >
                {matches ? (
                  <Typography sx={{ color: "#fff", fontSize: "16px" }} fontWeight={activeStep === 0 ? "700 !important" : "400 !important"}>
                    {activeStep === 0 ? "Personal Information" : activeStep === 1 ? "File Uploads" : "Payout"}
                  </Typography>
                ) : (
                  <>
                    <Typography sx={{ color: "#fff", fontSize: "16px" }} fontWeight={activeStep === 0 ? "700 !important" : "400 !important"}>
                      Personal Information
                    </Typography>
                    <ChevronRightIcon sx={{ color: "#a9aeaf" }} />
                    <Typography sx={{ color: "#fff", fontSize: "16px" }} fontWeight={activeStep === 1 ? "700 !important" : "400 !important"}>
                      File Uploads
                    </Typography>
                    <ChevronRightIcon sx={{ color: "#a9aeaf" }} />
                    <Typography sx={{ color: "#fff", fontSize: "16px" }} fontWeight={activeStep === 2 ? "700 !important" : "400 !important"}>
                      Payout
                    </Typography>
                  </>
                )}
              </Box>
            </>
          )}

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mt="20px" pb={"20px"}>
                {formContent(activeStep)}
              </Box>
            </form>
          </FormProvider>
        </Container>
      </Box>
    </>
  );
}
