import StatisticItem from "./StatisticItem";
import WhitepaperTag from "./WhitepaperTag";

function Whitepaper() {
  return (
    <section id="our-whitepaper" className="flex flex-col px-6 mt-8 pb-[100px] lg:pb-[120px] w-full text-sm font-bold text-[#192022]">
      <div className="flex flex-col lg:w-[1091px] mx-auto">
        <div className="flex flex-col lg:flex-row lg:gap-[112px]">
          <img src="/assets/images/white-paper-mobile.png" className="object-contain w-full aspect-square lg:w-[500px]" alt="Whitepaper Illustration" />
          <div className="flex-1">
            <h2 className="self-start mt-8 text-[32px] leading-none">Our Whitepaper</h2>

            <div className="flex gap-4 mt-6 w-full">
              <WhitepaperTag text="Non Fungible" />
              <WhitepaperTag text="Limited Supply" />
            </div>

            <div className="flex gap-[16px] lg:gap-[24px] mt-[16px] lg:mt-[24px] max-w-full whitespace-nowrap w-[232px] lg:w-full lg:items-start">
              <WhitepaperTag text="Marketplace" />
              <WhitepaperTag text="Fair" />
              <WhitepaperTag text="Transparent" className="self-start max-w-[137px] hidden lg:block" />
            </div>
          </div>
        </div>

        <WhitepaperTag text="Transparent" className="self-start mt-[16px] max-w-[137px] mb-[24px] block lg:hidden" />

        <div className="flex flex-col lg:flex-row lg:justify-evenly">
          <StatisticItem value="10%" description="Lorem Ipsum" />

          <StatisticItem value="20K" description="Lorem Ipsum" />
        </div>
      </div>
    </section>
  );
}

export default Whitepaper;
